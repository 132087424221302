import React, { FC } from 'react';

import ExtraGap from 'components/common/ExtraGap';
import NFButton from 'components/common/NFButton';
import ControlledCarousel from 'components/ControlledCarousel';

import { ProductsCarouselProps } from './model';
import './ProductsCarousel.scss';

const ProductsCarousel: FC<ProductsCarouselProps> = ({
  title,
  subtitle,
  btn,
  carouselClass,
  children,
  responsiveObj,
  fontColor,
  bgColor = 'white',
  ariaLabelPrev,
  ariaLabelNext,
  hideControls,
}) => (
  <div className="nf-products-carousel">
    {title ? (
      <h2
        className="nf-products-carousel__title"
        style={fontColor && { color: `var(--${fontColor})` }}
      >
        {title}
      </h2>
    ) : null}
    {subtitle ? <p className="nf-products-carousel__subtitle">{subtitle}</p> : null}
    {title && !subtitle ? <ExtraGap /> : null}
    <ControlledCarousel
      bgGradientColor={bgColor}
      classes={`${carouselClass}-carousel`}
      responsiveObj={responsiveObj}
      {...(!hideControls && {
        carouselControls: {
          ariaLabelPrev,
          ariaLabelNext,
        },
      })}
    >
      {children}
    </ControlledCarousel>
    {btn ? <NFButton {...btn} /> : null}
  </div>
);

export default ProductsCarousel;
